
import React from 'react';
import image1 from '../../screenshots/IMG_0015.png'
import image2 from '../../screenshots/IMG_0016.png'
import image3 from '../../screenshots/IMG_0018.png'
import image4 from '../../screenshots/IMG_0019.png'

function RecoverPasswordIos() {
  return (
    <div className='main'>
      <h1 className='terms--header'>How to Recover Your Password on iOS</h1>
      
      <div className='instructions'>
        <p className='instructionParagraph'>
          <strong>1.</strong> Open your app and click the <strong>Forgot Password</strong> button.
        </p>
        <img src={image1} alt='Forgot Password screen' className='instructionImage' />

        <p className='instructionParagraph'>
          <strong>2.</strong> Enter your email in lowercase. Click <strong>Send Email</strong> button.
        </p>
        <img src={image2} alt='Enter email screen' className='instructionImage' />

        <p className='instructionParagraph'>
          <strong>3.</strong> You will receive an email with a link that redirects you to the password recovery webpage. 
          If you haven’t received the email, please check your spam or junk mail folder, as it may have been filtered there. 
          If the link does not work, copy it and open it in a different browser.
        </p>
        <img src={image3} alt='Password recovery email' className='instructionImage' />

        <p className='instructionParagraph'>
          <strong>4.</strong> There, you will need to enter your new password twice. Remember your new password.
        </p>
        <img src={image4} alt='Enter new password' className='instructionImage' />

        <p className='instructionParagraph'>
          <strong>5.</strong> Do not log in on the website. Open your app and try logging in using your email and new password.
        </p>
        <img src={image1} alt='Log in screen' className='instructionImage' />

        <p className='instructionParagraph'>
          If you still do not see the email after multiple attempts, please contact us directly at{' '}
          <a href="mailto:sorttoys@gmail.com" className='emailLink'>sorttoys@gmail.com</a>. 
          Thank you for your patience and for using our app. We value your feedback and are committed to ensuring it meets your needs.
        </p>
      </div>
    </div>
  );
}

export default RecoverPasswordIos;

// import React from 'react'

// function RecoverPasswordIos() {
//   return (
//     <div className='main'>
//         <h1 className='terms--header'>How to Recover Your Password on iOS</h1>
        
//         <div className='instructions'>
//         <p className='instructionParagraph'>
//             <strong>1.</strong> Open your app and click the <strong>Forgot Password</strong> button.
//         </p>
//         <p className='instructionParagraph'>
//             <strong>2.</strong> Enter your email in lowercase. Click <strong>Send Email</strong> buttton.
//         </p>
//         <p className='instructionParagraph'>
//             <strong>3.</strong> You will receive an email with a link that redirects you to the password recovery webpage. If you haven’t received the email, please check your spam or junk mail folder, as it may have been filtered there. If the link does not work, copy it and open it in a different browser.
//         </p>
//         <p className='instructionParagraph'>
//             <strong>4.</strong> There, you will need to enter your new password twice. Remember your new password.
//         </p>
//         <p className='instructionParagraph'>
//             <strong>5.</strong> Do not log in on the website. Open your app and try logging in using your email and new password.
//         </p>
//         <p className='instructionParagraph'>
//             If you still do not see the email after multiple attempts, please contact us directly at{' '}
//             <a href="mailto:sorttoys@gmail.com" className='emailLink'>sorttoys@gmail.com</a>. 
//             Thank you for your patience and for using our app. We value your feedback and are committed to ensuring it meets your needs.
//         </p>
//         </div>
//     </div>
//   )
// }

// export default RecoverPasswordIos